* {
  margin: 0;
  padding: 0;
}


#banner {
  height: 300px;
  background-color: yellow;
}

#banner > img {
  width: 100%;
  height: 300px;
}

#body > h1 {
  margin-top: 16px;
}

#product-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}

.product-card {
  width: 180px;
  height: 300px;
  margin-right: 12px;
  margin-bottom: 12px;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 12px;
  overflow: hidden;
}

.product-img {
  width: 100%;
  height: 210px;
}

.product-contents {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.product-name {
  font-size: 14px;
}

.product-price {
  font-size: 16px;
  font-weight: 600;
  margin-top: 4px;
}

.product-seller {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.product-avatar {
  width: 24px;
}

.product-link {
  color: inherit;
  text-decoration: none;
}